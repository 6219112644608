@import "ag-grid-community/dist/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/_ag-theme-alpine-mixin";
@import "../variables";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      header-background-color: null,
      font-family: (
        "IBM Plex Sans",
        "sans-serif",
      ),
    )
  );

  .ag-header-cell-text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #494e5b;
  }

  .ag-header {
    border-bottom: none;
  }

  .ag-root-wrapper {
    border: none;
  }
}

.m9-ag-grid-theme {
  .ag-root-wrapper {
    background: transparent;
  }

  .ag-header {
    background: #fff;
    box-shadow: 0 24px 32px -16px rgba(161, 174, 183, 0.15);
    border-radius: 0 0 12px 12px;
  }

  .ag-row {
    background: transparent;
    
    .ag-cell {
      display: flex;
      align-items: center;
    }
  }
}