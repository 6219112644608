@import "/src/ui/styles/variables";

#drop_zone {
  &:hover, &.active {
    border: 2px dashed $color-primary;
  }
  &.error {
    border: 2px dashed $color-error;
  }
  .download-file-icon {
    position: relative;
    .MuiSvgIcon-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    svg {
      font-size: 34px;
    }
  }
}