@import "/src/ui/styles/variables";

.UserMessageWrapper {
  @apply p-3 bg-gray-150 w-fit rounded-xl shrink text-gray-500 relative;

  &:after {
    @apply bg-gray-150;
    position: absolute;
    bottom: -5px;
    left: 3.5px;
    border-radius: 50%;
    content: " ";
    width: 10px;
    height: 10px;
  }

  &:before {
    @apply bg-gray-150;
    position: absolute;
    top: calc(100% + 5px);
    left: 0px;
    border-radius: 50%;
    content: " ";
    width: 4px;
    height: 4px;
  }
}

.Message {
  h1,
  h2,
  h3 {
    line-height: 1.2;
  }

  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  code {
    white-space: break-spaces;
  }
}

.Loader {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  .DotFlashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-gray-400;
    color: $color-gray-400;
    animation: DotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .DotFlashing::before,
  .DotFlashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .DotFlashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-gray-400;
    color: $color-gray-400;
    animation: DotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .DotFlashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-gray-400;
    color: $color-gray-400;
    animation: DotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes DotFlashing {
    0% {
      background-color: $color-gray-500;
    }
    50%,
    100% {
      background-color: $color-gray-300;
    }
  }
}
