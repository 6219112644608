.m9slider {
  .swiper-wrapper {
    padding-bottom: 40px;
  }

  &--stretch {
    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      display: flex;
      height: auto;
    }
  }
}
