@import "/src/ui/styles/variables";

.Content {
  background: url("../../../assets/svg/BG\ lines.svg");
}

.IconContent {
  svg {
    font-size: 24px;
  }
}

.IconWrapper {
  border-radius: 16px;
  border: 1px solid $color-gray-200;
  background: white;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) inset, 0px 0px 0px 2px white;
  padding: 20px;
}
