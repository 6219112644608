@import "/src/ui/styles/variables";

.aside-active-icon {
    color: $color-primary;
    path {
        fill: $color-primary;
    }
}

.aside-icon {
    color: $color-gray-400;
    path {
        fill: $color-gray-400;
    }
}